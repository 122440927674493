// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Project from "../../../../models/Project.res.js";
import * as IconFile from "../../../../styleguide/icons/IconFile.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconMapView from "../../../../styleguide/icons/IconMapView.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserNetworkPrivateLineProject_Details_LocationDocumentsScss from "./UserNetworkPrivateLineProject_Details_LocationDocuments.scss";

var css = UserNetworkPrivateLineProject_Details_LocationDocumentsScss;

function UserNetworkPrivateLineProject_Details_LocationDocuments$DocumentRow(props) {
  var $$document = props.document;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(IconFile.make, {
                              size: "LG",
                              color: "DarkGray",
                              className: css.icon
                            }),
                        JsxRuntime.jsx("p", {
                              children: $$document.fileName,
                              className: css.fileName
                            })
                      ],
                      className: css.fileNameAndIconWrapper
                    }),
                JsxRuntime.jsx(Button.AsLink.make, {
                      href: Routes_Project.Network.$$Document.download(props.projectId, $$document.id),
                      size: "SM",
                      color: "Teal",
                      ghost: true,
                      buttonClassName: css.downloadButton,
                      children: "Download"
                    })
              ],
              className: css.row
            });
}

var DocumentRow = {
  make: UserNetworkPrivateLineProject_Details_LocationDocuments$DocumentRow
};

function UserNetworkPrivateLineProject_Details_LocationDocuments(props) {
  var projectId = props.projectId;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h3", {
                      children: "Documents with Multiple Locations and Requirements",
                      className: css.documentsHeading
                    }),
                JsxRuntime.jsx("p", {
                      children: "These documents include additional locations and requirements.",
                      className: css.descriptionContent
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.map(props.documents, (function ($$document) {
                              return JsxRuntime.jsx(UserNetworkPrivateLineProject_Details_LocationDocuments$DocumentRow, {
                                          document: $$document,
                                          projectId: projectId
                                        }, "document" + Project.DocumentId.toString($$document.id));
                            })),
                      className: css.panelBody
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(IconMapView.make, {
                                      size: "LG",
                                      color: "DarkGray",
                                      className: css.icon
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: "Geographical region:",
                                      className: css.geographicalRegionTitle
                                    })
                              ],
                              className: css.geographicalRegionAndIconWrapper
                            }),
                        JsxRuntime.jsxs("p", {
                              children: [
                                props.geographicalRegion,
                                " only"
                              ],
                              className: css.geographicalRegion
                            })
                      ],
                      className: css.geographicalRegionRow
                    })
              ],
              className: css.panelContainer
            });
}

var make = UserNetworkPrivateLineProject_Details_LocationDocuments;

export {
  css ,
  DocumentRow ,
  make ,
}
/* css Not a pure module */
